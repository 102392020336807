import ComingSoon from 'components/ComingSoon';
import Fallback from 'components/Fallback';
import SideMenu from 'components/SideMenu';
import VideoBox from 'components/VideoBox';
import { logEvent } from 'firebase-analytics';
import { fetchContent } from 'global/api';
import { FETCH_ALL_TUTORIAL_CONTENT, FETCH_TUTORIAL_CONTENT, GRAY_COLOR, MOBILE_RED_PRIMARY_COLOR, RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import MobileTutorials from '../Mobile/Tutorials';

const Tutorials = props => {

    const { translations, responsive } = props;
    const styles = {
        container: {
            display: 'flex',
            width: '100%',
            marginTop: "3em"
        },
        leftContainer: {
            display: 'flex',
            alignItems: 'flex-start', marginLeft: '6em',
            flexDirection: 'column', flex: "25%",
        },
        description: {
            fontSize: '14px', color: "#fff", maxHeight: '40vh', overflowY: "auto"
        },
        rightContainer: {
            display: 'flex', flexDirection: 'column',
            alignItems: 'center', flex: "75%",
            maxHeight: "calc(100vh - 16em)",
            overflow: 'auto'
        }
    }

    const [leftContainer, setLeftContainer] = useState();
    const [rightContainer, setRightContainer] = useState({ videos: [] });
    const [loading, setLoading] = useState(true);
    const [activeId, setActiveId] = useState();
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const [serverResponse, setServerResponse] = useState(false);

    useEffect(() => {
        setIsError(false)
        fetchTutorialContent();
    }, [])

    useEffect(() => {
        if (isError)
            props.history.replace('errorPage')
    }, [isError]);

    useEffect(() => {
        if (activeId) {
            const menu = leftContainer.menu.find(item => {
                return item.sequenceNo == activeId
            })
            const video = rightContainer.videos.find(_ => _.category === menu.title);
            if (video) {
                setVideo(video);
            } else {
                setVideo("default");
            }
        }
    }, [activeId])

    useEffect(() => {
        // check for any data in search query params;
        if (serverResponse) {
            if (location && location.state && location.state.searchQueryData) {
                setRightContainer({ videos: [location.state.searchQueryData] })
            }
            setLoading(false)
        }
    }, [serverResponse, location])

    const fetchTutorialContent = () => {
        logEvent(`tutorials_content_load_start`);
        fetchContent(FETCH_ALL_TUTORIAL_CONTENT, {}).then((response) => {
            if (response) {
                if (response.code === 2000) {
                    const data = response.data;
                    if (data) {
                        const leftContainer = data.leftContainer;
                        setLeftContainer(leftContainer);
                        setRightContainer(data.rightContainer);
                        setServerResponse(true)
                    } else {
                        setLoading(false)
                    };
                    logEvent(`tutorials_content_load_success`);
                } else {
                    setIsError(true);
                    setLoading(false);
                    logEvent(`tutorials_content_load_fail`);
                }
            } else {
                setIsError(true);
                setLoading(false);
                logEvent(`tutorials_content_load_fail`);
            }
        });
    }

    const [video, setVideo] = useState();

    const [videoLoading, setVideoLoading] = useState(false);

    useEffect(() => {
        setVideoLoading(video ? video === "default" ? false : video.mediaUrl ? false : true : false);
        const id = setTimeout(() => {
            setVideoLoading(false)
        }, 10000);

        return () => clearTimeout(id);
    }, [video])

    return loading ? <Fallback loading={loading}
        color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR} /> :
        !responsive ? <div style={styles.container} >
            <div style={styles.leftContainer} className="left-container">
                <div className="title">
                    {leftContainer.title}
                </div>
                <div className="Line-2">
                </div>
                <div style={styles.description}>
                    <SideMenu styles={{ marginBottom: 7, labelWidth: 110 }}
                        menu={leftContainer.menu} activeId={activeId}
                        selectMenuItemHandler={setActiveId} />
                </div>
            </div>
            <div style={styles.rightContainer}>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    width: "100%", justifyContent: 'center'
                }}>
                    {!video ?
                        rightContainer.videos.length !== 0 ? <div style={{
                            display: "grid", gap: 20, width: "100%",
                            gridTemplateColumns: "repeat(auto-fill, 240px)",
                            paddingRight: 30, justifyContent: 'center'
                        }}>
                            {rightContainer.videos.map(video => {
                                return <VideoBox onClick={() => setVideo(video)} width={240} height={220}
                                    backgroundColor={GRAY_COLOR}
                                    title={video.displayTitle} description={video.description} />
                            })}
                        </div> : <ComingSoon translations={translations} /> :
                        video === "default" ? <ComingSoon translations={translations} /> : <div style={{
                            display: 'flex', width: "100%", justifyContent: 'center',
                            flexDirection: 'column', alignItems: 'center', height: 330,
                            position: 'relative', marginTop: '1em', marginRight: '2em'
                        }}>
                            <div onClick={() => {
                                setVideo();
                                setActiveId();
                            }} style={{ cursor: 'pointer', display: 'flex', width: '100%' }}>
                                <img src="/iPayV2/icons/backicon.svg" />
                            </div>
                            {videoLoading && <Fallback loading={videoLoading}
                                style={{ position: "absolute", height: "300px" }}
                                color={RED_PRIMARY_COLOR} />}
                            {video.mediaUrl ? <video autoPlay="true"
                                controls
                                playsInline
                                loop
                                onLoadCapture={() => setVideoLoading(false)}
                                style={{ height: "300px", width: "530px" }}>
                                <source src={video.mediaUrl} type="video/mp4" />
                            </video> : <iframe
                                allowFullScreen={true}
                                onLoadCapture={() => setVideoLoading(false)}
                                loading="lazy" src={video.redirectUrl}
                                style={{ height: "300px", width: "530px", objectFit: 'contain' }}
                            />}
                        </div>
                    }
                </div>
            </div>
        </div> : <MobileTutorials {...props} leftContainer={leftContainer} />
}

export default withRouter(Tutorials);