import { getIconURL } from "global/utils";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// import ActiveIcon from "../../../../../public/iPayV2/icons/activecashinout.svg";

const MobileTariff = (props) => {
  const {
    translations,
    lang,
    leftContainer,
    rightContainer = { rightContainer },
  } = props;
  return (
    <div className="mobile-container">
      {leftContainer && (
        <>
          <div className="mobile-title" style={{ maxWidth: "330px" }}>
            {leftContainer.title}
          </div>
          <div
            className="mobile-title"
            style={{
              fontSize: 16,
              display: "flex",
              margin: "1em 0",
              textTransform: "unset",
            }}
          >
            {leftContainer.assets && (
              <div style={{ display: "flex" }}>
                <img
                  src={leftContainer.assets.mediaUrl}
                  style={{ width: 40 }}
                />
              </div>
            )}
            <div
              style={{
                marginLeft: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ lineHeight: "20px", fontWeight: "bold" }}>
                {leftContainer.title}
              </div>
              <div style={{ fontWeight: "normal" }}>
                {leftContainer.subtitle}
              </div>
            </div>
          </div>
        </>
      )}
      <div
        style={{
          background: "#fff",
          width: "calc(100% - 51px)",
          padding: "25px 25px 0px 26px",
          height: "calc(100% - 100px)",
          border: "1px solid #CCCCCC",
          borderRadius: "30px 30px 0px 0px",
          borderBottom: "unset",
        }}
      >
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          <table style={{ direction: lang === "ar" ? "rtl" : undefined }}>
            {rightContainer.tariffDetails?.length > 0 && (
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: lang === "ar" ? "right" : undefined,
                    }}
                  >
                    {rightContainer.tariffDetails[0].tariffChart}
                  </th>
                  <th
                    style={{
                      textAlign: lang === "ar" ? "right" : undefined,
                    }}
                  >
                    {rightContainer.tariffDetails[0].charges}
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {rightContainer.tariffDetails?.slice(1).map((tariff) => (
                <tr>
                  <td
                    style={{
                      textAlign: lang === "ar" ? "right" : undefined,
                    }}
                  >
                    {tariff.tariffChart}
                  </td>
                  <td
                    style={{
                      textAlign: lang === "ar" ? "right" : undefined,
                    }}
                  >
                    {tariff.charges}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {rightContainer.assets && (
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            width: "calc(100% - 51px)",
            fontFamily: "'Source Sans Pro'",
            lineHeight: "22px",
            color: "#414142",
            direction: lang === "ar" ? "rtl" : undefined,
            background: "#F7F7F7",
            padding: "19px 25px 18px 26px",
            borderRadius: "0px 0px 30px 30px",
            border: "1px solid #CCCCCC",
          }}
        >
          {rightContainer.assets.displayTitle}
        </div>
      )}
    </div>
  );
};

export default withRouter(MobileTariff);
