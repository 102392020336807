import classes from "./ourProducts.module.css";

import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { withRouter } from "react-router-dom";
import {
  FETCH_ALL_CATEGORIES,
  GRAY_FOOTER_COLOR,
  HOME_PAGE_URL,
  maxDots,
  MOBILE_RED_PRIMARY_COLOR,
  QA_TYPE_IMAGE,
  QA_TYPE_VIDEO,
  RED_PRIMARY_COLOR,
  TRACKING_ID,
  WHITE_PRIMARY_COLOR,
} from "global/constants";

const middleContainer = {
  assetsMiddle: [
    {
      id: 785,
      assetId: "785",
      pageNo: 0,
      sequenceNo: null,
      mediaUrl: "/iPayV2/videos/consumer.mp4",
      assestType: "video",
      redirectUrl: null,
      isRedirected: null,
      title: "Our Products",
      displayTitle: "Our Products",
      description: "video",
      containerType: "middle",
      category: null,
      language: "en",
      active: true,
    },
  ],
};

const OurProducts = (props) => {
  const { translations, fromEnquiry, responsive } = props;

  const [videoPausedByUser, setVideoPausedByUser] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const slideRef = useRef();

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "45px",
      width: "100%",
      marginTop: "2em",
      height: "calc(100% - 1em)",
      alignItems: "center",
    },
    leftContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    middleContainer: {
      width: "100%",
      height: responsive ? "126px" : "400px",
    },
    subHeading: {
      alignItems: "center",
      fontSize: "24px",
      lineHeight: "1.20",
      marginBottom: "10px",
      color: "#2A292B",
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      flex: "30%",
      maxHeight: "calc(100vh - 16em)",
      marginTop: "30px",
    },
    storeContainer: {
      display: "flex",
      alignItems: "center",
    },
  };

  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => {
      // setCurrentSlide(index);
      const el = document.querySelector(".slick-current video");
      if (el && !videoPausedByUser) {
        el.play();
      }
    },
  };

  const nextHandler = () => {
    if (slideRef && slideRef.current) {
      slideRef.current.slickNext();
    }
  };

  const prevHandler = () => {
    if (slideRef && slideRef.current) {
      slideRef.current.slickPrev();
    }
  };

  const redirectHandler = (redirectUrl) => {
    if (redirectUrl.startsWith("http")) {
      window.open(redirectUrl);
    } else {
      props.history.push(redirectUrl);
    }
  };

  useEffect(() => {
    const hashValue = window.location.hash.replace("#", "");
    document.getElementById(hashValue)?.scrollIntoView({ behavior: "smooth" });
  }, [window.location.hash]);

  return (
    <>
      <div
        onClick={() => {
          window.open("https://wa.me/message/CXPUN6JZQKSNO1", "_blank");
        }}
        className={classes.whatsApp}
      >
        <div className={classes.icon}>
          <img src={"/iPayV2/icons/whatsApp.svg"} />
        </div>
        <div className={classes.needSupport}>
          Need Support?<br></br> WhatsApp us
        </div>
      </div>
      <div style={{ marginBottom: 45 }}>
        <div style={styles.middleContainer} className="middle-container">
          <div
            id={responsive ? "home-mobile-slider" : "homesliderContainer"}
            style={
              responsive
                ? {
                    width: "100%",
                    height: "126px",
                    WebkitBackgroundClip: "padding-box",
                  }
                : {
                    width: "100%",
                    height: "400px",
                  }
            }
          >
            <Slider {...settings} ref={slideRef}>
              {middleContainer &&
              middleContainer?.assetsMiddle &&
              middleContainer?.assetsMiddle?.length > 0 ? (
                middleContainer?.assetsMiddle?.map((asset, index) => {
                  let content;
                  if (asset?.assestType === QA_TYPE_IMAGE) {
                    content = (
                      <div
                        style={{
                          width: "100px",
                          height: responsive ? "126px" : "400px",
                        }}
                        key={index + ""}
                        onClick={() => {
                          if (asset.isRedirected && asset.redirectUrl)
                            return redirectHandler(asset.redirectUrl);
                          else return null;
                        }}
                      >
                        <img
                          style={{
                            height: responsive ? "126px" : "400px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                            ...(asset.isRedirected && { cursor: "pointer" }),
                            ...(!asset.isRedirected && { cursor: "default" }),
                          }}
                          src={asset.mediaUrl}
                          alt={asset.displayTitle}
                        />
                      </div>
                    );
                  } else if (asset?.assestType === QA_TYPE_VIDEO) {
                    content = (
                      <div
                        style={{
                          width: "100%",
                          height: responsive ? "126px" : "400px",
                          backgroundColor: RED_PRIMARY_COLOR,
                        }}
                        key={index + ""}
                      >
                        {asset.mediaUrl && (
                          <video
                            style={{
                              height: responsive ? "126px" : "400px",
                              display: "block",
                              margin: "auto",
                              width: "100%",
                              backgroundColor: RED_PRIMARY_COLOR,
                            }}
                            controls
                            src={asset.mediaUrl}
                            id={"video_" + index}
                            onPause={() => {
                              setVideoPausedByUser(true);
                              setAutoplay(true);
                            }}
                            onEnded={() => {
                              setVideoPausedByUser(false);
                              nextHandler();
                            }}
                            alt={asset.displayTitle}
                            onPlay={() => {
                              setAutoplay(true);
                            }}
                          />
                        )}
                      </div>
                    );
                  } else {
                    content = <div key={index + ""}>{asset.description}</div>;
                  }
                  return content;
                })
              ) : (
                <div className="hand-image">
                  <img src={"/iPayV2/icons/hand-image.png"} />
                </div>
              )}
            </Slider>
          </div>
          {!responsive && middleContainer?.assetsMiddle?.length > 1 && (
            <div
              className={classes.homeSliderButtons}
              onClick={prevHandler}
              style={{
                left: "4%",
                bottom: "53%",
                position: "relative",
                border: "1px solid #2A292B",
                backgroundColor: "#2A292B",
                borderRadius: "50%",
                height: "36px",
                width: "36px",
                opacity: "0.4",
                cursor: "pointer",
              }}
            >
              <button
                style={{
                  margin: "0px 10px",
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "white",
                }}
                id="prevButton"
              >
                &lt;
              </button>
            </div>
          )}
          {!responsive && middleContainer?.assetsMiddle?.length > 1 && (
            <div
              className={classes.homeSliderButtons}
              onClick={nextHandler}
              style={{
                position: "relative",
                left: "94%",
                bottom: "63%",
                border: "1px solid #2A292B",
                backgroundColor: "#2A292B",
                borderRadius: "50%",
                height: "36px",
                width: "36px",
                opacity: "0.4",
                cursor: "pointer",
              }}
            >
              <button
                style={{
                  margin: "0px 10px",
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "white",
                }}
                id="prevButton"
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={classes.container}>
        <div
          id="internationalTransfer"
          className={classes.information + " " + styles.columnReverse}
        >
          {responsive ? (
            <GenericInfoSectionMobile
              translations={translations}
              title={"International Transfer"}
              initialDescription={[
                <span>
                  {
                    translations[
                      "Is it a game idea? Or a business? Or anything else?."
                    ]
                  }
                </span>,
                translations[
                  "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                ],
              ]}
            >
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </GenericInfoSectionMobile>
          ) : (
            <>
              <GenericInfoSection
                translations={translations}
                title={"International Transfer"}
                initialDescription={[
                  <span>
                    {
                      translations[
                        "Is it a game idea? Or a business? Or anything else?."
                      ]
                    }
                  </span>,
                  translations[
                    "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                  ],
                ]}
              />
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div id="billPayments" className={classes.information}>
          {responsive ? (
            <GenericInfoSectionMobile
              translations={translations}
              title={"Bill Payments"}
              initialDescription={
                translations[
                  "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself."
                ]
              }
            >
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_03.png"} />
                </div>
              </div>
            </GenericInfoSectionMobile>
          ) : (
            <>
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_03.png"} />
                </div>
              </div>
              <GenericInfoSection
                translations={translations}
                title={"Bill Payments"}
                initialDescription={
                  translations[
                    "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself."
                  ]
                }
              />
            </>
          )}
        </div>
        <div
          id="offers"
          className={classes.information + " " + styles.columnReverse}
        >
          {responsive ? (
            <GenericInfoSectionMobile
              translations={translations}
              title={"Offers"}
              initialDescription={[
                <span>
                  {
                    translations[
                      "Is it a game idea? Or a business? Or anything else?."
                    ]
                  }
                </span>,
                translations[
                  "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                ],
              ]}
            >
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_04.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </GenericInfoSectionMobile>
          ) : (
            <>
              <GenericInfoSection
                translations={translations}
                title={"Offers"}
                initialDescription={[
                  <span>
                    {
                      translations[
                        "Is it a game idea? Or a business? Or anything else?."
                      ]
                    }
                  </span>,
                  translations[
                    "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                  ],
                ]}
              />
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_04.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div id="merchantPayments" className={classes.information}>
          {responsive ? (
            <GenericInfoSectionMobile
              translations={translations}
              title={"Merchant Payments"}
              initialDescription={
                translations[
                  "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself."
                ]
              }
            >
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                </div>
              </div>
            </GenericInfoSectionMobile>
          ) : (
            <>
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                </div>
              </div>
              <GenericInfoSection
                translations={translations}
                title={"Merchant Payments"}
                initialDescription={
                  translations[
                    "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself."
                  ]
                }
              />
            </>
          )}
        </div>
        <div
          id="iPayPayrollProduct"
          className={classes.information + " " + styles.columnReverse}
        >
          {responsive ? (
            <GenericInfoSectionMobile
              translations={translations}
              title={"iPay Payroll Product"}
              initialDescription={[
                <span>
                  {
                    translations[
                      "Is it a game idea? Or a business? Or anything else?."
                    ]
                  }
                </span>,
                translations[
                  "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                ],
              ]}
            >
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </GenericInfoSectionMobile>
          ) : (
            <>
              <GenericInfoSection
                translations={translations}
                title={"iPay Payroll Product"}
                initialDescription={[
                  <span>
                    {
                      translations[
                        "Is it a game idea? Or a business? Or anything else?."
                      ]
                    }
                  </span>,
                  translations[
                    "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                  ],
                ]}
              />
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div id="iPayCards" className={classes.information}>
          {responsive ? (
            <GenericInfoSectionMobile
              translations={translations}
              title={"iPay Cards"}
              initialDescription={
                translations[
                  "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself."
                ]
              }
            >
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                </div>
              </div>
            </GenericInfoSectionMobile>
          ) : (
            <>
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                </div>
              </div>
              <GenericInfoSection
                translations={translations}
                title={"iPay Cards"}
                initialDescription={
                  translations[
                    "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself."
                  ]
                }
              />
            </>
          )}
        </div>
        <div
          id="walletTransfer"
          className={classes.information + " " + styles.columnReverse}
        >
          {!responsive ? (
            <>
              <GenericInfoSection
                translations={translations}
                title={"Wallet Transfer"}
                initialDescription={[
                  <span>
                    {
                      translations[
                        "Is it a game idea? Or a business? Or anything else?."
                      ]
                    }
                  </span>,
                  translations[
                    "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                  ],
                ]}
              />
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </>
          ) : (
            <GenericInfoSectionMobile
              translations={translations}
              title={"Wallet Transfer"}
              initialDescription={[
                <span>
                  {
                    translations[
                      "Is it a game idea? Or a business? Or anything else?."
                    ]
                  }
                </span>,
                translations[
                  "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects."
                ],
              ]}
            >
              <div className={classes.imageSec}>
                <div>
                  <img src={"/iPayV2/icons/iphone12_01.png"} />
                  <img
                    src={"/iPayV2/icons/iphone12_02.png"}
                    className={classes.imageTwo}
                  />
                </div>
              </div>
            </GenericInfoSectionMobile>
          )}
        </div>
      </div>
    </>
  );
};

const GenericInfoSection = ({
  translations,
  title,
  initialDescription,
  additionalDescription,
  id,
}) => {
  const [showMore, setShowMore] = useState(false);

  const handleKnowMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div id={id} className={classes.infoSec}>
      <div className={classes.title}>{translations[title]}</div>
      <div className={classes.description}>
        {Array.isArray(initialDescription) ? (
          initialDescription.map((element, index) => {
            return <div key={index}>{element}</div>;
          })
        ) : (
          <div>{initialDescription}</div>
        )}
        {showMore && (
          <div>
            {Array.isArray(additionalDescription) ? (
              additionalDescription.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))
            ) : (
              <p>{additionalDescription}</p>
            )}
          </div>
        )}
      </div>
      {additionalDescription && (
        <div className={classes.knowmore} onClick={handleKnowMoreClick}>
          {showMore ? "Show Less" : "Know More"}
          <img
            src={"/iPayV2/icons/chevron.svg"}
            width={18}
            height={18}
            alt="Arrow"
          />
        </div>
      )}
    </div>
  );
};

const GenericInfoSectionMobile = ({
  translations,
  title,
  initialDescription,
  additionalDescription,
  id,
  children,
}) => {
  const [showMore, setShowMore] = useState(false);

  const handleKnowMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div id={id} className={classes.infoSec}>
      <div className={classes.title}>{title}</div>
      <div className={classes.mobileImageDiv}>{children}</div>
      <div className={classes.description}>
        {Array.isArray(initialDescription) ? (
          initialDescription.map((element, index) => (
            <div key={index}>{element}</div>
          ))
        ) : (
          <div>{initialDescription}</div>
        )}
        {showMore && (
          <div>
            {Array.isArray(additionalDescription) ? (
              additionalDescription.map((paragraph, index) => (
                <p key={index}>{translations[paragraph]}</p>
              ))
            ) : (
              <p>{additionalDescription}</p>
            )}
          </div>
        )}
      </div>
      {additionalDescription && (
        <div className={classes.knowmore} onClick={handleKnowMoreClick}>
          {showMore ? "Show Less" : "Know More"}
          <img
            src={"/iPayV2/icons/chevron.svg"}
            width={18}
            height={18}
            alt="Arrow"
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(OurProducts);
