import Fallback from "components/Fallback";
import Wrapper from "components/Wrapper";
import { GRAY_COLOR, GRAY_FOOTER_COLOR, RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from "global/constants";
import { useEffect, useState } from "react";
const DiscoverFold1 = props => {

    const { pauseSlide, resumeSlide, handleVideosCompleted, data } = props;

    const [leftContainer, setLeftContainer] = useState();
    const [rightContainer, setRightContainer] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const { leftContainer, rightContainer } = data;
        const { assets = [] } = rightContainer;
        let modifiedAssets = [];
        if (assets) {
            modifiedAssets = assets.map((asset, index) => {
                return { ...asset, sequenceNo: (index + 1) + "" }
            })
        }
        const modifiedRightContainer = { ...rightContainer, assets: modifiedAssets };
        setLeftContainer(leftContainer)
        setRightContainer(modifiedRightContainer)
        setLoading(false)
    }, [])

    const styles = {
        container: {
            display: 'flex',
            flexDirection: "row",
            width: '100%',
            marginTop: "3em"
        },
        leftContainer: {
            display: 'flex',
            alignItems: 'flex-start', marginLeft: '6em',
            flexDirection: 'column', flex: "20%"
        },
        description: {
            fontSize: '24px', color: GRAY_FOOTER_COLOR,
        },
        rightContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
            flex: "80%",
            marginTop: '-2em'
        }
    }

    const [currentVideoId, setCurrentVideoId] = useState("1");
    const [currentVideo, setCurrentVideo] = useState();

    useEffect(() => {
        let video;
        if (rightContainer && rightContainer.assets) {
            video = rightContainer.assets.find(asset => asset.sequenceNo == currentVideoId);
        }
        setCurrentVideo(video)
    }, [currentVideoId, rightContainer])

    useEffect(() => {
        if (document.querySelector(`video[id="${currentVideoId}"]`))
            document.querySelector(`video[id="${currentVideoId}"]`).play()
    }, [currentVideo])

    const handleVideoAutoPlay = (id) => {
        if (rightContainer && rightContainer.assets && rightContainer && rightContainer.assets.length >= id) {
            // if (handleVideosCompleted) handleVideosCompleted(false)
            setCurrentVideoId(id + "");
        }
        else {
            setTimeout(() => {
                setCurrentVideoId("1")
            }, 1000);
            if (handleVideosCompleted) handleVideosCompleted(true)
        }
    }
    const onEndedHandler = event => {
        const id = +event.target.id + 1;
        handleVideoAutoPlay(id);
    }
    return (loading ? <Fallback loading={loading} /> : <div style={styles.container} id="discover1">
        <div style={styles.leftContainer} className="left-container">
            <div className="title">
                {leftContainer.title}
            </div>
            <br />
            <div className="Line-2">
            </div>
            <br />
            <div style={styles.description}>
                {leftContainer.description}
            </div>
        </div>
        <div style={styles.rightContainer} onMouseOver={pauseSlide} onMouseLeave={resumeSlide}>
            <div style={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'center', paddingTop: '2em',
                backgroundRepeat: 'no-repeat', width: '60%',
                backgroundPosition: 'center',
                justifyContent: 'center',
                backgroundSize: 'contain',
                backgroundImage: "url('/iPayV2/icons/discoverbg.png')"
            }}>
                <div class="marvel-device iphone-x" style={{
                    width: 500, height: 240, zIndex: 0,
                    boxShadow: "#000 -1px -1px 1px 0px", borderRadius: 30, padding: "6px 7px",
                    background: "linear-gradient(153deg, #cacaca 196%, #c1c1c1 205%, #a9a9a9 219%, #838383 237%, #5b5b5b 254%, #f2f2f2 268%, #969696 293%)"
                }}>
                    <div class="overflow" style={{ borderRadius: 30 }}>
                        <div class="shadow shadow--tr"></div>
                        <div class="shadow shadow--tl"></div>
                        <div class="shadow shadow--br"></div>
                        <div class="shadow shadow--bl"></div>
                    </div>
                    <div class="screen" style={{
                        borderRadius: 30, textAlign: 'center',
                        boxShadow: '0 0 0 3px #111'
                    }}>
                        {/* <!-- Content goes here --> */}
                        <div style={{
                        }}>
                            {currentVideo && <video muted autoPlay
                                style={{
                                    width: '100%', width: "500px", height: "240px",
                                }}
                                id={currentVideoId} controls src={currentVideo.mediaUrl}
                                onEnded={onEndedHandler} />}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex', flexDirection: 'column', marginTop: '1em',
                maxHeight: 'calc(100vh - 14em)', overflow: 'auto'
            }} id="gridContainer">
                <div className="discover_grid_container" style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto', gap: '10px',
                }}>
                    {rightContainer && rightContainer.assets.map(asset => {
                        return <Grid  {...asset} currentVideoId={currentVideoId}
                            handleClick={() => handleVideoAutoPlay(+asset.sequenceNo)} />
                    })}
                </div>
            </div>
        </div>
    </div>);
}

const Grid = (props) => {

    const { sequenceNo, description, displayTitle, handleClick, currentVideoId } = props;

    return (<Wrapper backgroundColor={RED_PRIMARY_COLOR} width="19em" id="d1wrapper">
        <div style={{
            display: 'grid', height: '7em',
            gap: '1em', cursor: 'pointer',
            color: WHITE_PRIMARY_COLOR,
            gridTemplateAreas: "'menu main'", padding: "5px 20px", gridTemplateColumns: '25% 70%'
        }} onClick={handleClick}>
            <div style={{
                gridArea: 'menu', fontSize: 50, fontWeight: 600, borderRadius: '50%', width: '100%',
                margin: 'auto', textAlign: 'center', border: `1px solid ${WHITE_PRIMARY_COLOR}`
            }}>
                {sequenceNo}
            </div>
            <div style={{ gridArea: 'main', fontSize: 14, margin: 'auto 0', fontWeight: 'lighter' }}>
                <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{displayTitle}</span><br />
                <span>{description}</span>
            </div>
        </div>
    </Wrapper >)
}
export default DiscoverFold1;