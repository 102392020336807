import Fallback from "components/Fallback";
import {
  FETCH_TARIFF_DETAILS,
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
} from "global/constants";
import { fetchContent } from "global/api";
import { logEvent } from "firebase-analytics";
import React, { useEffect, useState } from "react";
import MobileTariff from "../Mobile/Tariff";

const Tariff = (props) => {
  const { translations, responsive } = props;

  const [loading, setLoading] = useState(true);

  const [isError, setIsError] = useState(false);
  const [leftContainer, setLeftContainer] = useState();
  const [rightContainer, setRightContainer] = useState();

  useEffect(() => {
    fetchContent(FETCH_TARIFF_DETAILS, {}).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setLeftContainer(data.leftContainer);
            setRightContainer(data.rightContainer);
          }
          setLoading(false);
          logEvent(`tariff_details_load_success`);
        } else {
          setIsError(true);
          setLoading(false);
          logEvent(`tariff_details_load_fail`);
        }
      } else {
        setIsError(true);
        setLoading(false);
        logEvent(`tariff_details_load_fail`);
      }
    });
  }, []);

  const lang = localStorage.getItem("locale");

  const styles = {
    container: {
      display: "flex",
      width: "100%",
      marginTop: "1em",
      height: "100%",
      alignItems: "center",
    },
    leftContainer: {
      display: "flex",
      alignItems: "flex-start",
      marginLeft: "6em",
      flexDirection: "column",
      flex: "25%",
    },
    description: {
      fontSize: "30px",
      textAlign: "left",
      color: "#2a292b",
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: "6em",
      flex: "75%",
      height: "100%",
      // overflow: 'hidden'
    },
    tableContainer: {
      background: "#fff",
      width: "90%",
      padding: "25px 25px 0px 26px",
      height: "calc(100% - 100px)",
      border: "1px solid #CCCCCC",
      borderRadius: "30px 30px 0px 0px",
      borderBottom: "unset",
    },
  };

  useEffect(() => {
    if (isError) props.history.replace("errorPage");
  }, [isError]);

  return loading ? (
    <Fallback
      loading={loading}
      color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR}
    />
  ) : !responsive ? (
    <div style={styles.container}>
      {leftContainer && (
        <div style={styles.leftContainer} className="left-container">
          <div className="title">{leftContainer.title}</div>
          <div className="Line-2" style={{ margin: "3% 0px" }}></div>
          <div style={styles.description}>{leftContainer.subtitle}</div>
        </div>
      )}
      {rightContainer && (
        <div style={styles.rightContainer}>
          <div style={styles.tableContainer}>
            <div style={{ overflowY: "auto", height: "100%" }}>
              <table style={{ direction: lang === "ar" ? "rtl" : undefined }}>
                {rightContainer.tariffDetails?.length > 0 && (
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: lang === "ar" ? "right" : undefined,
                        }}
                      >
                        {rightContainer.tariffDetails[0].tariffChart}
                      </th>
                      <th
                        style={{
                          textAlign: lang === "ar" ? "right" : undefined,
                        }}
                      >
                        {rightContainer.tariffDetails[0].charges}
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {rightContainer.tariffDetails?.slice(1).map((tariff) => (
                    <tr>
                      <td
                        style={{
                          textAlign: lang === "ar" ? "right" : undefined,
                        }}
                      >
                        {tariff.tariffChart}
                      </td>
                      <td
                        style={{
                          textAlign: lang === "ar" ? "right" : undefined,
                        }}
                      >
                        {tariff.charges}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {rightContainer.assets && (
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                width: "90%",
                fontFamily: "'Source Sans Pro'",
                lineHeight: "22px",
                color: "#414142",
                background: "#F7F7F7",
                direction: lang === "ar" ? "rtl" : undefined,
                padding: "19px 25px 18px 26px",
                borderRadius: "0px 0px 30px 30px",
                border: "1px solid #CCCCCC",
              }}
            >
              {rightContainer.assets.displayTitle}
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <MobileTariff
      {...props}
      lang={lang}
      rightContainer={rightContainer}
      leftContainer={leftContainer}
    />
  );
};

export default Tariff;
